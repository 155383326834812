var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"storyline-item",class:{
    expand: _vm.isExpanded,
    edit: _vm.isEditInProgress,
    drag: !_vm.isEditInProgress && !_vm.readOnly,
    selected: _vm.selected,
    'error-item': _vm.storyline.error,
  },on:{"click":function($event){return _vm.expand()}}},[(_vm.isEditInProgress)?_c('div',{staticClass:"edit-panel"},[_c('span',[_vm._v("Edit text on slide")]),_c('div',{staticClass:"edit-actions"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"disabled":_vm.valueSame || !_vm.valid,"size":"24","color":_vm.valueSame || !_vm.valid ? 'ccc' : '#00925A',"name":"add-context-icon","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.saveEdit()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,false,3331390764)},[_c('span',[_vm._v("Save")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-btn",attrs:{"size":"24","color":"#D32F2F","name":"add-context-icon","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.closeEdit()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,false,3871719395)},[_c('span',[_vm._v("Dismiss")])])],1)]):_vm._e(),_c('div',{staticClass:"storyline-main-section",class:{
      'non-editable': _vm.storyline.slide_type === 'thank_you' || _vm.storyline.error,
    }},[_c('v-icon',{staticClass:"storyline-drag"},[_vm._v(" mdi-drag-vertical ")]),_c('div',{staticClass:"storyline-index"},[_vm._v(_vm._s(_vm.padIndex))]),(_vm.storyline.error)?_c('div',{staticClass:"storyline-title-error"},[_c('v-icon',{staticClass:"err-icon",attrs:{"size":"24","color":'#D32F2F'}},[_vm._v(" mdi-alert ")]),_c('span',[_vm._v("Oops something went wrong")])],1):(_vm.storyline.slide_type === 'thank_you')?_c('div',{staticClass:"storyline-title"},[_vm._v(" Thank you ")]):_c('div',{staticClass:"storyline-title"},[_c('StorylineTextarea',{ref:"titletextarea",attrs:{"readOnly":_vm.isReadOnly,"maxLength":_vm.maxTitle},model:{value:(_vm.storylineObj.title),callback:function ($$v) {_vm.$set(_vm.storylineObj, "title", $$v)},expression:"storylineObj.title"}})],1),_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-icons",attrs:{"size":"18","color":"#000","name":"add-context-icon","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.edit()}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}])},[_c('span',[_vm._v("Edit text on slide")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action-icons",attrs:{"size":"18","color":"#000","name":"add-context-icon","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.expand()}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])]}}])},[_c('span',[_vm._v("Expand")])])],1)],1),(_vm.storyline.slide_type === 'discussion_topic')?_c('div',{staticClass:"storyline-sub-section"},[_vm._l((_vm.storylineObj.content),function(header,ind){return [_c('StorylineTextarea',{key:'content' + ind,ref:"textareas",refInFor:true,attrs:{"showBar":"","readOnly":_vm.isReadOnly,"maxLength":_vm.maxContent},model:{value:(_vm.storylineObj.content[ind]),callback:function ($$v) {_vm.$set(_vm.storylineObj.content, ind, $$v)},expression:"storylineObj.content[ind]"}})]})],2):(_vm.storyline.slide_type !== 'thank_you')?_c('div',{staticClass:"storyline-sub-section"},[(_vm.storylineObj.subtitle !== null)?_c('StorylineTextarea',{ref:"textareas",attrs:{"showBar":"","readOnly":_vm.isReadOnly,"maxLength":_vm.maxSubTitle},model:{value:(_vm.storylineObj.subtitle),callback:function ($$v) {_vm.$set(_vm.storylineObj, "subtitle", $$v)},expression:"storylineObj.subtitle"}}):_vm._e(),_vm._l((_vm.storylineObj.section_header),function(header,ind){return [_c('StorylineTextarea',{key:'header' + ind,ref:"textareas",refInFor:true,attrs:{"showBar":"","readOnly":_vm.isReadOnly,"maxLength":_vm.maxHeader},model:{value:(_vm.storylineObj.section_header[ind]),callback:function ($$v) {_vm.$set(_vm.storylineObj.section_header, ind, $$v)},expression:"storylineObj.section_header[ind]"}}),_c('StorylineTextarea',{key:'content' + ind,ref:"textareas",refInFor:true,attrs:{"prefix":"","readOnly":_vm.isReadOnly,"maxLength":_vm.maxContent},model:{value:(_vm.storylineObj.content[ind]),callback:function ($$v) {_vm.$set(_vm.storylineObj.content, ind, $$v)},expression:"storylineObj.content[ind]"}})]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }