export const AddNewAudienceModalProps = {
  name: 'AddCollegueDetail',
  classes: 'v-application',
  width: '578px',
  height: 'auto',
  styles: {
    borderRadius: '20px',
    padding: '30px',
    'max-height': '800px',
    'min-height': '400px',
    'overflow-y': 'auto',
    'overflow-x': 'hidden',
    height: 'auto !important',
  },
};
