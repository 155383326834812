<template>
  <div class="node-count-container">
    <div
      class="heading"
      :style="{
        marginBottom: '24px',
      }"
    >
      {{ $t('generate.nodeCount') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="sub-heading">
      Select the number of columns and rows for your table
    </div>
    <div class="node-content">
      <template>
        <div class="grid-container" @mouseleave="onLeave">
          <div v-for="(row, y) in grid" :key="'row-' + y" class="grid-row">
            <div
              v-for="(cell, x) in row"
              :key="'cell-' + x + '-' + y"
              class="grid-cell"
              :class="{
                selected: cell.x <= selectedColumn && cell.y <= selectedRow,
                hover: cell.x <= hoverColumn && cell.y <= hoverRow,
                disabled: cell.x > maxColumns || cell.y > maxRows,
              }"
              @mouseover="onHover(cell.x, cell.y)"
              @click="onSelect(cell.x, cell.y)"
            ></div>
          </div>
          <div class="column-display">
            <span class="number-container">
              <span class="number-wrapper" :style="rowStyle">
                <span v-for="num in numbers" :key="'row-' + num">{{
                  num
                }}</span>
              </span>
            </span>
            x
            <span class="number-container">
              <span class="number-wrapper" :style="columnStyle">
                <span v-for="num in numbers" :key="'col-' + num">{{
                  num
                }}</span>
              </span>
            </span>
            Table
          </div>
        </div>
      </template>
      <div class="footNote">{{ $t('generate.nodeCountGridFootNote') }}</div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';

export default {
  name: 'TableNodeCount',
  props: {
    maxRows: {
      type: Number,
      default: 10, // Max allowed rows
    },
    maxColumns: {
      type: Number,
      default: 10, // Max allowed columns
    },
    node: {
      type: Array,
      default: () => [1, 1],
    },
  },
  data() {
    return {
      grid: Array.from({ length: 10 }, (_, y) =>
        Array.from({ length: 10 }, (__, x) => ({ x: x + 1, y: y + 1 })),
      ),
      selectedRow: 0,
      selectedColumn: 0,
      hoverRow: 0,
      hoverColumn: 0,
      numbers: Array.from({ length: 10 }, (_, i) => i + 1), // Numbers 0-9
    };
  },
  watch: {
    node(val) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedRow = val[0];
      // eslint-disable-next-line prefer-destructuring
      this.selectedColumn = val[1];
    },
  },
  methods: {
    onHover(x, y) {
      if (x <= this.maxColumns && y <= this.maxRows) {
        this.hoverColumn = x;
        this.hoverRow = y;
      }
    },
    onLeave() {
      this.hoverColumn = 0;
      this.hoverRow = 0;
    },
    onSelect(x, y) {
      if (x <= this.maxColumns && y <= this.maxRows) {
        this.selectedColumn = x;
        this.selectedRow = y;
        console.log(`Selected coordinate: (${x}, ${y})`);
        this.$emit('change', { type: 'node_change', nodeCount: [y, x] });
      }
    },
  },
  computed: {
    rowStyle() {
      return {
        transform: `translateY(-${
          (this.hoverRow || this.selectedRow) * 100 - 100
        }%)`,
        transition: 'transform 0.5s ease-in-out',
      };
    },
    columnStyle() {
      return {
        transform: `translateY(-${
          (this.hoverColumn || this.selectedColumn) * 100 - 100
        }%)`,
        transition: 'transform 0.5s ease-in-out',
      };
    },
  },
  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.selectedRow = this.node[0];
    // eslint-disable-next-line prefer-destructuring
    this.selectedColumn = this.node[1];
  },
};
</script>
<style lang="scss" scoped>
.node-count-container {
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    //  margin-bottom: 24px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .node-content {
    height: calc(100% - 70px);
    overflow: auto;
    padding: 5px;

    .grid-container {
      display: flex;
      flex-direction: column;
      gap: 12px; /* Spacing between rows */
      justify-content: center;
      padding: 30px;
      gap: 12px;
      background: #fbfbfb;
      width: min-content;

      .column-display {
        margin-top: 6px;
        font-family: Lato, sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        height: 24px;
        overflow: hidden;
      }

      .number-container {
        display: inline-block;
        width: 24px;
        height: 24px;
        overflow: hidden;
        position: relative;
        font-weight: bold;
        top: 6px;
      }

      .number-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 100%;
      }

      .number-wrapper span {
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .grid-row {
      display: flex;
      gap: 12px; /* Spacing between cells */
    }

    .grid-cell {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1.12px solid #c1c7cd;
      font-size: 14px;
      cursor: pointer;
      transition: background-color 0.2s ease;
    }

    .grid-cell.selected {
      background: #dde1e6; /* Selected row/column highlight */
      border: 1px solid #dde1e6;
    }

    .grid-cell.hover {
      border: 1px solid #21a7e0;
      background: #b3e4f5;
    }

    .grid-cell.disabled {
      background-color: #e0e0e0; /* Disabled cell color */
      color: #999;
      cursor: not-allowed;
    }

    .footNote {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.01em;
      padding: 21px 0px 0px 0px;
      color: #697077;
    }
  }
}
</style>
