<template>
  <v-container fluid>
    <!-- <div
      v-if="
        currentUser.user.complyBanner === -1 &&
        currentUser.user.role.toLowerCase() !== 'trialuser' &&
        isComplyFullAccessBannerCheck() &&
        doUserHavePremiumSubscription
      "
      class="generate-banner"
    >
      Template Converter is still in BETA. It is constantly changing and
      evolving to produce better results.
      <button @click="onDismiss" class="dismiss-btn primary--text">
        Dismiss
      </button>
    </div> -->
    <div class="comply-content">
      <div
        class="comply-main-section"
        :class="{ 'right-panel-open': rightPanelComponent }"
      >
        <div class="comply-top-row">
          <div class="page-heading" ref="mainDiv">
            <v-tooltip
              top
              fixed
              content-class="tooltip-content"
              v-if="rightPanelComponent && isSmallScreen"
            >
              <template v-slot:activator="{ on }">
                <div class="main add-ellipses" v-on="on">
                  Upload the slides you would like to convert to your brand
                  template
                </div>
              </template>
              <span>
                Upload the slides you would like to convert to your brand
                template
              </span>
            </v-tooltip>
            <div class="main" v-else>
              Upload the slides you would like to convert to your brand template
            </div>
            <div class="sub">
              {{ 'Powered by ASTRID™ AI' }}
            </div>
          </div>
          <QuickAccessToolbarComponent
            :isAddedToLibrary="isAddedToLibrary"
            :addToLibrary="updateAddedToSlideLibraryStatus"
            :handleClosePopAndSaveToLib="handleClosePopAndSaveToLib"
          >
            <template>
              <div v-if="addToLibraryEnabled">
                <v-tooltip
                  bottom
                  max-width="250"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                  v-if="!removingFromLibrary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        depressed
                        small
                        icon
                        :ripple="false"
                        :color="
                          hover || isAddedToLibrary
                            ? '#21A7E0'
                            : !getComplianceScore
                            ? '#757575'
                            : '#000'
                        "
                        :style="
                          !getComplianceScore
                            ? {
                                pointerEvents: 'none',
                                cursor: 'not-allowed',
                              }
                            : {}
                        "
                        :disabled="isActionLoading"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleAddToSlideLibrary"
                        data-pendo-id="compliance-checker-add-to-library"
                      >
                        <v-icon
                          name="add-to-library-icon"
                          data-pendo-id="generate-add-to-library"
                        >
                          {{
                            isAddedToLibrary
                              ? isMoreSlides
                                ? 'mdi-folder'
                                : 'mdi-folder-table'
                              : isMoreSlides
                              ? 'mdi-folder-outline'
                              : 'mdi-folder-table-outline'
                          }}
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>{{ $t(addToLibraryTooltip) }}</span>
                </v-tooltip>
                <v-progress-circular
                  indeterminate
                  :color="'#21A7E0'"
                  :size="25"
                  v-if="isAddedToLibrary && removingFromLibrary"
                />
              </div>
              <div v-if="addToLibraryEnabled">
                <v-tooltip
                  bottom
                  max-width="250"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                  v-if="!removingAsFavorite"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-btn
                        depressed
                        small
                        icon
                        :ripple="false"
                        :color="
                          hover || isAddedAsFavorite
                            ? '#21A7E0'
                            : !getComplianceScore
                            ? '#757575'
                            : '#000'
                        "
                        :style="
                          !getComplianceScore
                            ? {
                                pointerEvents: 'none',
                                cursor: 'not-allowed',
                              }
                            : {}
                        "
                        :disabled="isActionLoading"
                        v-bind="attrs"
                        v-on="on"
                        @click="handleAddToFavorites()"
                        data-pendo-id="compliance-checker-favorite"
                      >
                        <v-icon
                          name="favorite-icon"
                          data-pendo-id="generate-add-to-favourite"
                        >
                          {{
                            isAddedAsFavorite
                              ? 'mdi-heart'
                              : 'mdi-heart-outline'
                          }}
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </template>
                  <span>{{ $t(addToFavoriteTooltip) }}</span>
                </v-tooltip>
                <v-progress-circular
                  indeterminate
                  :color="'#21A7E0'"
                  :size="25"
                  v-if="isAddedAsFavorite && removingAsFavorite"
                />
              </div>
            </template>
          </QuickAccessToolbarComponent>
        </div>
        <div class="comply-main-content">
          <manage-comply-upload
            @onCancelledCta="handleOnCancelledCta"
            :handleOnFileUpload="handleOnFileUpload"
            :handleOnReCheckComply="handleOnCheckCompliance"
            :resetLocalFileInfo="resetLocalFileData"
            :inTemplateSelectionStep="
              templatesListResponse &&
              !showSlideContent &&
              !initialComplianceCheckFailed
            "
            :loading="checkingUsage.value"
          ></manage-comply-upload>
          <slides-placeholder
            v-show="
              (!fileDetails &&
                !rightPanelComponent &&
                !loading.fileUploadLoading) ||
              initialComplianceCheckFailed ||
              selectedSlidesComplianceCheckFailed
            "
          ></slides-placeholder>
          <progress-animation-loader
            v-if="loading.fileUploadLoading"
          ></progress-animation-loader>
          <ComplyDetails
            v-if="
              showSlideContent &&
              !(
                (!fileDetails &&
                  !rightPanelComponent &&
                  !loading.fileUploadLoading) ||
                initialComplianceCheckFailed ||
                selectedSlidesComplianceCheckFailed
              )
            "
            @update-main-slide-height="handleMainSlideHeightUpdate"
          />
          <slides-listing
            :slidesList="slidesListResponse.slide_images"
            :loading="loading.templateListLoading"
            @handleOnNext="handleOnSlidesSelectionCta"
            v-if="isFinalSlideDataAvailable && !showSlideContent"
          ></slides-listing>
          <templates-listing
            :templatesData="templatesListResponse"
            :loading="loading.intialComplianceLoading"
            :templateRequestFrom="'Template Converter'"
            @onPrimaryBtnClick="handleOnTemplateSelection"
            v-if="
              templatesListResponse &&
              !showSlideContent &&
              !initialComplianceCheckFailed
            "
          ></templates-listing>
        </div>
      </div>
      <SmartToolsMenu v-if="isSmartToolsOpen" />
      <ComplySettingsPanel
        :slide-height="mainSlideHeight"
        @handleOnCheckCompliance="handleOnCheckCompliance"
        :clearSlideLibraryFlags="clearSlideLibraryFlags"
        :isAddedToLibrary="isAddedToLibrary"
        :addToLibrary="updateAddedToSlideLibraryStatus"
        :handleClosePopAndSaveToLib="handleClosePopAndSaveToLib"
      />
      <!-- <LibraryConfimationPopup
        v-if="isAddedToLibraryDownload"
        :goToLibrary="goToLibrary"
        :message="
          totalSlides > 1
            ? $t('premiumFeature.addedToPrezLibrary')
            : $t('premiumFeature.addedToSlideLibrary')
        "
        :btnText="$t('premiumFeature.openInLibrary')"
        :cancel="closePopup"
      ></LibraryConfimationPopup> -->
    </div>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '../../common/event-bus';
import ManageComplyUpload from './ManageComplyUpload.vue';
import SlidesPlaceholder from './SlidesPlaceholder.vue';
import ComplySettingsPanel from './comply-settings/ComplySettingsPanel.vue';
import ComplyDetails from './comply-deck-detail-main/ComplyDetails.vue';
import QuickAccessToolbarComponent from './QuickAccessToolbarComponent.vue';
import SlidesListing from './comply-slides-listing-page/SlidesListing.vue';
import TemplatesListing from './comply-templates-listing-page/TemplatesListing.vue';
import ProgressAnimationLoader from './ProgressAnimationLoader.vue';
import GenerateActionModal from '../GenerateSlides/GenerateActionModal.vue';
// import LibraryConfimationPopup from '../../common/LibraryConfimationPopup.vue';
// import usersApi from '../../../API/users-api';
import SmartToolsMenu from './comply-settings/SmartToolsMenu.vue';
import {
  addPrezentationAsFavorite,
  addPrezentationDeck,
  createFavorite,
  createSlide,
  deleteFavorite,
  deleteSlide,
  // generateSlidePostCompleteLog,
  getFeatureUsage,
  getPipeineStatus,
  getSlideDetail,
  postFeatureUsage,
  removeFromLibrary,
  removePrezentationAsFavorite,
  uploadPPTXStartRequest,
} from '../../../utils/api-helper';
import { trackComplyEvents } from '../../common/Analytics/ComplyEvents';
import {
  TD_ISFAVORITED,
  TD_PREZNAME,
  TD_SLIDE_NAME,
  TD_TEMPLATE,
  TD_SLIDES_LIBRARY,
  TD_PREZENTATION_LIBRARY,
  TD_LIBRARY,
  TD_NUMBEROFSLIDES,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_FILE_SIZE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
import { miscConfig } from '../../../runtimeConfig';
import { ERROR_CODES } from './utils/comply-helper.utils';

export default {
  name: 'ComplyMain',
  components: {
    ComplySettingsPanel,
    ManageComplyUpload,
    SlidesPlaceholder,
    QuickAccessToolbarComponent,
    ComplyDetails,
    SlidesListing,
    TemplatesListing,
    ProgressAnimationLoader,
    SmartToolsMenu,
    // LibraryConfimationPopup,
  },
  computed: {
    ...mapState('comply', [
      'rightPanelComponent',
      'isSmartToolsOpen',
      'selectedSlides',
      'templatesInfo',
      'slidesInfo',
      'fileDetails',
      'selectedTemplate',
    ]),
    ...mapGetters('comply', [
      'getComplianceScore',
      'totalSlides',
      'isActionLoading',
      'getFileDetails',
      'isTemplateConvertedCompleted',
    ]),
    ...mapState('users', ['currentUser', 'limitsInfoObj']),
    ...mapGetters('users', [
      'getUserVisualPreference',
      // 'doUserHavePremiumSubscription',
      'isComplyFullAccess',
    ]),
    isFinalSlideDataAvailable() {
      return (
        this.slidesListResponse &&
        this.slidesListResponse.slide_images?.length > 0 &&
        this.prefetchedTemplatesList
      );
    },
    addToLibraryTooltip() {
      if (this.isAddedToLibrary) {
        return this.addedToLibraryData.type === 'slide'
          ? 'generateToolBar.remove_from_slide_library'
          : 'generateToolBar.remove_from_prez_exchange';
      }
      return this.slidesListResponse?.slide_images?.length > 1
        ? 'generateToolBar.add_to_prez_exchange'
        : 'generateToolBar.add_to_slide_library';
    },
    addToFavoriteTooltip() {
      if (this.isAddedAsFavorite) {
        return this.addedToLibraryData.type === 'slide'
          ? 'premiumFeature.removeSlideFromFavourite'
          : 'premiumFeature.removePrezentationFromFavourite';
      }
      return this.slidesListResponse?.slide_images?.length > 1
        ? 'premiumFeature.addPrezentationToFavourites'
        : 'premiumFeature.addSlideToFavourites';
    },
    isMoreSlides() {
      return this.slidesListResponse?.slide_images?.length > 1;
    },

    // Property to get whether add to library is enabled
    addToLibraryEnabled() {
      const addToLibFeature = this.currentUser.features.find(
        (ft) => ft.feature_name === 'add_to_library',
      );
      if (addToLibFeature) {
        return addToLibFeature.enabled;
      }
      return false;
    },
  },
  data() {
    return {
      checkingUsage: { value: false },
      mainSlideHeight: 0,
      showSlideContent: false,
      showDeckDetails: false,
      uploadFileProcessing: false,
      complyDeckData: {},
      slidesListResponse: null,
      templatesListResponse: null,
      // isAddedToLibraryDownload: false,
      startSaveChanges: false,
      loading: {
        fileUploadLoading: false,
        templateListLoading: false,
        intialComplianceLoading: false,
      },
      initialComplianceCheckFailed: false,
      resetLocalFileData: false,
      selectedSlidesComplianceCheckFailed: false,
      generateOperation: {
        loading: false,
        success: false,
      },
      isAddedToLibrary: false,
      prezOrSlideId: null,
      isAddedAsFavorite: false,
      addedToLibraryData: null,
      removingFromLibrary: false,
      removingAsFavorite: false,
      prefetchedTemplatesList: null,
      // isOverflowed: false,
      isSmallScreen: false,
    };
  },
  watch: {
    slidesInfo(slidesInfo) {
      if (slidesInfo && Object.keys(slidesInfo).length > 0) {
        this.slidesListResponse = {
          ...slidesInfo,
          slide_images: Array.isArray(slidesInfo.slide_images)
            ? slidesInfo.slide_images
            : Object.values(slidesInfo.slide_images),
        };
      }
      // console.log('*******', this.slidesListResponse, slidesInfo);
    },
    rightPanelComponent: {
      handler(val) {
        if (val) {
          this.checkScreenSize();
        } else {
          this.isSmallScreen = false;
        }
      },
    },
  },
  methods: {
    ...mapActions('users', ['setLimitData', 'setUpgradePopup']),
    ...mapActions('comply', ['resetState']),
    ...mapActions('commonDownloads', [
      // 'setDownloadItems',
      // 'setShowDownloadSnackbar',
      // 'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
      'setAddedToLibOrFavSnackbar',
      'setNavFromAddToLibOrFavSnackbar',
    ]),
    handleMainSlideHeightUpdate(newHeight) {
      this.mainSlideHeight = newHeight;
    },
    handleUploadProgress(isLoading) {
      this.uploadFileProcessing = isLoading;
    },
    goToFavLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: this.totalSlides > 1 ? 'addToFavPrez' : 'addToFavSlides',
        origin: this.totalSlides > 1 ? 'fav_prez' : 'fav_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.totalSlides > 1) {
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        this.$router.push('/home/slides').catch(() => {});
      }
    },
    goToLibrary() {
      this.setNavFromAddToLibOrFavSnackbar({
        navigated: true,
        type: this.totalSlides > 1 ? 'addToLibPrez' : 'addToLibSlides',
        origin: this.totalSlides > 1 ? 'comply_lib_prez' : 'comply_lib_slides',
      });
      this.$modal.hide('GenerateActionModal');
      if (this.totalSlides > 1) {
        this.$router.push('/home/prezentations').catch(() => {});
      } else {
        this.$router.push('/home/slides').catch(() => {});
      }
    },
    // closePopup() {
    //   // this.isAddedToLibraryDownload = false;
    //   this.resetAddedToLibrarySnackbarState();
    // },
    pageChangeAction(navigationNext) {
      this.navigationNext = navigationNext;
      const filename = this.modifiedFileName || this.filename;
      this.$modal.show(
        GenerateActionModal,
        {
          heading: this.$t('premiumFeature.unsavedChanges'),
          primaryBtnText: this.$t('common.save'),
          secondaryBtnText: this.$t('premiumFeature.continueWithoutSaving'),
          modalType: 'unsaved',
          primaryBtnAction: this.saveChanges,
          secondaryBtnAction: this.discardChanges,
          type:
            this.slidesListResponse?.slide_images.length > 1
              ? 'prezentation'
              : 'slide',
          operation: this.generateOperation || {},
          filename,
          downloadItem:
            this.selectedSlides.length > 1
              ? {
                  name: filename,
                  categories: ['title'],
                  prefs: {
                    // ...this.selectedSlide.slideDetails.prefs,
                    source: 'comply',
                  },
                  filename,
                  noofslides: this.selectedSlides.length,
                }
              : {
                  name:
                    this.modifiedFileName ||
                    `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                  prefs: {
                    source: 'comply',
                  },
                  filename: this.selectedSlides[0]?.pptx?.s3_path,
                  noofslides: this.selectedSlides.length,
                },
          originPage: 'comply',
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          closePreviousPopups: this.handleClosePopAndSaveToLib,
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
    },
    async saveChanges(name) {
      if (this.addToLibraryEnabled) {
        this.startSaveChanges = true;
        await this.updateAddedToSlideLibraryStatus(name);
      } else {
        this.resetState();
        this.$modal.hide('GenerateActionModal');
        this.discardChanges();
      }
    },
    discardChanges() {
      this.navigationNext();
    },
    async handleOnTemplateSelection(template) {
      this.showSlideContent = true;
      this.$store.dispatch('comply/setTcErrorCode', '');
      try {
        let allSelectedSlidesVerified = false;
        while (!allSelectedSlidesVerified) {
          const verifyStatus = await this.$store.dispatch(
            'comply/fetchSlidesAndVerifyWithPoll',
            this.fileDetails.uploadId,
          );
          if (verifyStatus) allSelectedSlidesVerified = true;
        }
        this.$store.dispatch(
          'comply/updateSelectedTemplate',
          template.template_internal_name,
        );
        if (allSelectedSlidesVerified) {
          this.handleOnCheckCompliance('');
        }
      } catch (err) {
        await this.$store.dispatch('comply/resetFileData');
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        this.loading.fileUploadLoading = false;
      }
    },
    async fetchSlidesData() {
      this.$store.dispatch('comply/setTcErrorCode', '');
      try {
        const slidesResponse = await this.$store.dispatch(
          'comply/fetchSlidesOnFirstStepWithPoll',
          this.fileDetails.uploadId,
        );
        const templatesListPrefetch = await this.$store.dispatch(
          'comply/fetchRecomendedTemplatesOnFirstStepWithPoll',
          this.fileDetails.uploadId,
        );
        this.prefetchedTemplatesList = templatesListPrefetch;
        this.slidesListResponse = slidesResponse;
        this.loading.fileUploadLoading = false;
      } catch {
        await this.$store.dispatch('comply/resetFileData');
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        this.loading.fileUploadLoading = false;
      }
    },
    async handleOnCancelledCta() {
      this.initialComplianceCheckFailed = false;
      this.selectedSlidesComplianceCheckFailed = false;
      await this.$store.dispatch('comply/abortSignal');
      this.$store.dispatch('comply/resetFileData');
      this.$store.dispatch('comply/resetComplianceData');
      if (this.rightPanelComponent)
        this.$store.dispatch('comply/setRightPanelComponent', null);
      if (this.isSmartToolsOpen)
        this.$store.dispatch('comply/openSmartTools', false);
      this.slidesListResponse = null;
      this.templatesListResponse = null;
      this.prefetchedTemplatesList = null;
      this.showSlideContent = false;
      this.loading.fileUploadLoading = false;
      this.resetSlideLibraryFlags();
    },
    async handleOnSlidesSelectionCta(slides) {
      this.$store.dispatch('comply/setTcErrorCode', '');
      this.selectedSlidesComplianceCheckFailed = false;
      this.$store.dispatch('comply/updateSelectedSlidesList', slides);
      this.loading.templateListLoading = true;
      try {
        // const templatesResponse = await this.$store.dispatch(
        //   'comply/fetchComplianceTemplatesWithPoll',
        //   this.fileDetails.uploadId,
        // );
        this.templatesListResponse = this.prefetchedTemplatesList;
        this.loading.templateListLoading = false;
        this.slidesListResponse = null;
      } catch (err) {
        console.log(err);
        this.templatesListResponse = null;
        this.prefetchedTemplatesList = null;
        this.loading.templateListLoading = false;
        this.slidesListResponse = null;
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        this.loading.fileUploadLoading = false;
        this.selectedSlidesComplianceCheckFailed = true;
      }
    },
    async handleOnCheckCompliance(flow) {
      this.$store.dispatch('comply/setTcErrorCode', '');
      this.initialComplianceCheckFailed = false;
      this.checkingUsage.value = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
          this.checkingUsage.value = false;
        })
        .catch((error) => {
          this.checkingUsage.value = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.comply?.isLimitCrossed ||
        this.limitsInfoObj?.comply?.isFullAccess
      ) {
        // if (flow === 'recheck-compliance') {
        postFeatureUsage({ feature: 'comply' })
          .then((response) => {
            if (response?.data) {
              this.setLimitData(response);
            }
          })
          .catch((error) => console.log(error));
        // }
        try {
          if (this.rightPanelComponent)
            this.$store.dispatch('comply/setRightPanelComponent', null);
          if (this.isSmartToolsOpen)
            this.$store.dispatch('comply/openSmartTools', false);
          this.loading.intialComplianceLoading = true;
          this.$store.dispatch('comply/setComplyCheckLoading', true);
          const intialCheckResponse = await this.$store.dispatch(
            'comply/addInitialComplianceCheck',
            flow,
          );
          await this.$store.dispatch(
            'comply/setNewUploadId',
            intialCheckResponse.data.upload_id,
          );
          this.showSlideContent = true;
          this.$store.dispatch('comply/resetComplianceData');
          const slidesResponse = await this.$store.dispatch(
            'comply/fetchComplianceSlidesWithPoll',
            this.fileDetails.uploadId,
          );
          console.log(
            'slidesResponse, slidesInfo',
            slidesResponse,
            this.slidesInfo,
          );
          this.slidesListResponse = {
            ...this.slidesInfo,
            slide_images: Array.isArray(this.slidesInfo.slide_images)
              ? this.slidesInfo.slide_images
              : Object.values(this.slidesInfo.slide_images),
          };
          this.loading.intialComplianceLoading = false;
          setTimeout(() => {
            this.$store.dispatch(
              'comply/setRightPanelComponent',
              'complianceScore',
            );
            this.$store.dispatch('comply/openSmartTools', true);
          }, 3000);
        } catch (error) {
          this.loading.intialComplianceLoading = false;
          this.initialComplianceCheckFailed = true;
          this.showSlideContent = false;
          this.templatesListResponse = null;
          this.prefetchedTemplatesList = null;
          this.$store.dispatch('comply/setComplyCheckLoading', false);
          this.$store.dispatch('comply/resetFileData');
          this.$store.dispatch('comply/resetComplianceData');
          this.resetLocalFileData = true;
          this.$store.dispatch(
            'comply/setTcErrorCode',
            ERROR_CODES.ComplianceCheckFailure,
          );
          // console.error('Error fetching slides:', error);
        }
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'comply' });
      }
      if (flow === 'recheck-compliance') {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
        };
        trackComplyEvents.complianceCheckerRecheckCompliance(
          this.currentUser,
          otherData,
        );
      }
    },
    async handleOnFileUpload(filesToUpload) {
      // this.localFiles = [...event.target.files];
      this.$store.dispatch('comply/setTcErrorCode', '');
      this.initialComplianceCheckFailed = false;
      this.checkingUsage.value = true;
      await getFeatureUsage()
        .then((response) => {
          if (response?.data) {
            this.setLimitData(response);
          }
          this.checkingUsage.value = false;
        })
        .catch((error) => {
          this.checkingUsage.value = false;
          console.log(error);
        });
      if (
        !this.limitsInfoObj?.comply?.isLimitCrossed ||
        this.limitsInfoObj?.comply?.isFullAccess
      ) {
        // postFeatureUsage({ feature: 'comply' })
        //   .then((response) => {
        //     console.log(response);
        //     if (response?.data) {
        //       this.setLimitData(response.data);
        //     }
        //   })
        //   .catch((error) => console.log(error));
        if (filesToUpload.length > 0) {
          try {
            this.loading.fileUploadLoading = true;
            const { name } = filesToUpload[0];
            this.filename = name && name.split('.')[0];
            if (filesToUpload[0].file.size / 1024 / 1024 > 200) {
              const otherData = {
                [TD_NUMBEROFSLIDES]:
                  this.slidesListResponse?.slide_images?.length,
                [TD_PREZNAME]: filesToUpload[0]?.name,
                [TD_FILE_SIZE]: `${
                  filesToUpload[0]?.file?.size
                    ? filesToUpload[0].file.size / 1024 / 1024
                    : 0
                } MB`,
              };
              trackComplyEvents.complianceCheckerUploadCheckComplianceFailed(
                this.currentUser,
                otherData,
              );
              await this.$store.dispatch('comply/resetFileData');
              this.$store.dispatch(
                'comply/setTcErrorCode',
                ERROR_CODES.FileSizeFailure,
              );
              this.loading.fileUploadLoading = false;
              this.initialComplianceCheckFailed = true;
            } else {
              const uploadedFileResponse = await this.$store.dispatch(
                'comply/uploadComplyFile',
                filesToUpload[0],
              );
              console.log(uploadedFileResponse);
              if (uploadedFileResponse.isSlidesThresholdExceeds) {
                const otherData = {
                  [TD_NUMBEROFSLIDES]: uploadedFileResponse.numOfPages,
                  [TD_PREZNAME]: filesToUpload[0]?.name,
                  [TD_FILE_SIZE]: `${
                    filesToUpload[0]?.file?.size
                      ? filesToUpload[0].file.size / 1024 / 1024
                      : 0
                  } MB`,
                };
                trackComplyEvents.complianceCheckerUploadCheckComplianceFailed(
                  this.currentUser,
                  otherData,
                );
                await this.$store.dispatch('comply/resetFileData');
                this.$store.dispatch(
                  'comply/setTcErrorCode',
                  ERROR_CODES.FileUploadFailure,
                );
                this.loading.fileUploadLoading = false;
                this.initialComplianceCheckFailed = true;
                return { slidesThresholdExceeds: true };
              }
              if (uploadedFileResponse) {
                await this.$store.dispatch('comply/getFileUploadId');
                this.fetchSlidesData();
              }
            }
          } catch (err) {
            console.log(err);
            const otherData = {
              [TD_NUMBEROFSLIDES]:
                this.slidesListResponse?.slide_images?.length,
              [TD_PREZNAME]: filesToUpload[0]?.name,
              [TD_FILE_SIZE]: `${
                filesToUpload[0]?.file?.size
                  ? filesToUpload[0].file.size / 1024 / 1024
                  : 0
              } MB`,
            };
            trackComplyEvents.complianceCheckerUploadCheckComplianceFailed(
              this.currentUser,
              otherData,
            );
            await this.$store.dispatch('comply/resetFileData');
            this.$store.dispatch(
              'comply/setTcErrorCode',
              ERROR_CODES.FileUploadFailure,
            );
            this.loading.fileUploadLoading = false;
          }
        }
      } else {
        this.setUpgradePopup({ upgradePopup: true, popupType: 'comply' });
      }
      return {};
    },

    // isComplyFullAccessBannerCheck() {
    //   const synFeature = this.currentUser?.features?.find(
    //     (ft) => ft.feature_name === 'comply',
    //   );
    //   return (
    //     synFeature &&
    //     synFeature.enabled &&
    //     synFeature.access_level &&
    //     synFeature.access_level === 'full_access'
    //   );
    // },
    // onDismiss() {
    //   const userData = {
    //     performIncrements: {
    //       complyBanner: 1,
    //     },
    //   };
    //   usersApi.methods
    //     .updateUserProfile(userData)
    //     .then((updatedUser) => {
    //       const userInfo = this.currentUser;
    //       userInfo.user = updatedUser;
    //       this.setCurrentUser(userInfo);
    //     })
    //     .catch((err) => console.log(err));
    // },

    resetSlideLibraryFlags() {
      this.isAddedToLibrary = false;
      this.isAddedAsFavorite = false;
      this.addedToLibraryData = null;
      this.modifiedFileName = '';
    },

    clearSlideLibraryFlags() {
      this.isAddedToLibrary = false;
      this.isAddedAsFavorite = false;
      this.modifiedFileName = '';
    },

    // postCompleteLog(type, val, uuid) {
    // const payload = {
    //   uuid,
    //   type,
    //   value: val,
    // };
    // generateSlidePostCompleteLog(payload).then((resp) => {
    //   console.log(resp);
    // });
    // },

    async addToFavorite() {
      if (this.addedToLibraryData.type === 'slide') {
        const favoriteData = {
          id: uuidv4(),
          assetID: this.addedToLibraryData.info.unique_id,
          source: this.addedToLibraryData.info.tags.source,
          themeID: this.selectedTemplate,
          tailorID: this.currentUser?.user?.id,
          audienceType: 'user',
        };
        await createFavorite(favoriteData)
          .then(() => {
            // this.postCompleteLog('favorite', true, favoriteData.id);
            this.isAddedAsFavorite = true;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      } else {
        await addPrezentationAsFavorite({
          prezentationId: this.addedToLibraryData.info.prezentation.id,
        })
          .then(() => {
            // this.postCompleteLog(
            //   'favorite',
            //   true,
            //   this.addedToLibraryData.info.prezentationId,
            // );
            this.isAddedAsFavorite = true;
            this.generateOperation.loading = false;
            this.generateOperation.success = true;
            // this.$modal.hide('GenerateActionModal');
          })
          .catch((error) => {
            console.error('Error occurred while creating slide:', error);
            this.generateOperation.loading = false;
            this.generateOperation.success = false;
          });
      }
    },

    formatProperty(str) {
      return str?.charAt(0) === '/' ? str : `/${str}`;
    },

    async updateAddedToSlideLibraryStatus(
      name,
      addFavorite = false,
      origin = '',
    ) {
      if (addFavorite)
        trackComplyEvents.complianceCheckerAddToFavourite(this.currentUser, {
          [TD_ISFAVORITED]: 1,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]:
            this.selectedSlides.length > 1
              ? TD_PREZENTATION_LIBRARY
              : TD_SLIDES_LIBRARY,
          [TD_SLIDE_NAME]: this.modifiedFileName || this.filename,
        });
      else
        trackComplyEvents.complianceCheckerAddToLibrary(this.currentUser, {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_COMMON_COLUMN_NAME]: TD_LIBRARY,
          [TD_COMMON_COLUMN_VALUE]:
            this.selectedSlides.length > 1
              ? TD_PREZENTATION_LIBRARY
              : TD_SLIDES_LIBRARY,
          [TD_SLIDE_NAME]: this.modifiedFileName || this.filename,
        });
      if (this.isAddedToLibrary && addFavorite) {
        await this.addToFavorite();
        this.setAddedToLibOrFavSnackbar({
          show: true,
          ctaText: 'snackBarItems.goToFavorites',
          snackbarText:
            this.slidesListResponse?.slide_images > 1
              ? 'snackBarItems.prezAddedToFav'
              : 'snackBarItems.slideAddedToFav',
          navFn: this.goToFavLibrary,
          snackbarType: 'goToFav',
        });
        return;
      }
      let s3Bucket = '';
      const s3Paths = this.slidesListResponse?.slide_images.map((d) => {
        s3Bucket = d.png.s3_bucket;
        return d.png.s3_path;
      });

      this.generateOperation.loading = true;
      const callbackId = uuidv4();
      await uploadPPTXStartRequest({
        callback_id: callbackId,
        report_id: uuidv4(),
        workflow_name: 'tags',
        workflow_context: {
          s3_bucket: s3Bucket,
          s3_paths: s3Paths,
        },
      })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });

      const getTagsData = async (tagsData) => {
        if (tagsData?.length) {
          this.modifiedFileName = name;
          if (this.slidesListResponse?.slide_images?.length > 1) {
            const payload = {
              source: 'comply',
              prezentation_name: name,
              audience_id: this.currentUser?.user?.id,
              audience_owner_id: this.currentUser?.user?.ownerID,
              audience_type: this.currentUser?.user?.type || 'Me',
              audience_num_id: this.currentUser?.user?.num_id,
              theme_code: this.selectedTemplate,
              add_to_prezentations: true,
              outline_description: 'Custom outline tailored to the audience',
              outline_type: 'Custom',
              reference: this.getFileDetails.uploadId,
              slides: tagsData.map((i, idx) => {
                const slideData = this.slidesListResponse?.slide_images[idx];
                console.log('slideData complyMainVue', slideData);
                const result = {
                  pptx_file: this.formatProperty(slideData?.pptx?.s3_path),
                  pdf_file: this.formatProperty(slideData?.png?.s3_path), // img location
                  slide_name: `${name}_Slide_${idx}`, // slide sequence  - ppt name + _Slide_idx
                  business_ideas: [i.category],
                  node_count: i.node,
                  ml_detected_tags: { ...i },
                  theme_code: this.selectedTemplate,
                  category: i.category || '',
                  constructs: i.construct || '',
                  tags: i.keywords,
                  acceptedRejectedCount: slideData?.acceptedRejectedCount || 0,
                };
                return result;
              }),
            };

            this.generateOperation.loading = true;
            await addPrezentationDeck(payload)
              .then(async (resp) => {
                // this.postCompleteLog(
                //   'added_to_library',
                //   true,
                //   resp.data?.prezentationId,
                // );
                this.addedToLibraryData = {
                  info: resp.data,
                  type: 'prezentation',
                };
                this.isAddedToLibrary = true;
                if (origin === 'download') {
                  // this.isAddedToLibraryDownload = true;
                  // this.setIsAddedToLibraryDownload(true);
                  // this.setPremiumFtLibConfPopupData({
                  //   message:
                  //     this.totalSlides > 1
                  //       ? this.$t('premiumFeature.addedToPrezLibrary')
                  //       : this.$t('premiumFeature.addedToSlideLibrary'),
                  //   btnText: this.$t('premiumFeature.openInLibrary'),
                  //   goToLibrary: this.goToLibrary,
                  //   cancel: this.closePopup,
                  // });
                  // setTimeout(() => {
                  //   // this.isAddedToLibraryDownload = false;
                  //   this.resetAddedToLibrarySnackbarState();
                  // }, 10000);
                  this.prezOrSlideId = resp.data.prezentation.id;
                }
                if (this.startSaveChanges) {
                  this.resetState();
                  this.$modal.hide('GenerateActionModal');
                  this.discardChanges();
                }
                this.generateOperation.loading = false;
                this.generateOperation.success = true;
                if (addFavorite) {
                  await this.addToFavorite();
                  this.setAddedToLibOrFavSnackbar({
                    show: true,
                    ctaText: 'snackBarItems.goToFavorites',
                    snackbarText: 'snackBarItems.prezAddedToFav',
                    navFn: this.goToFavLibrary,
                    snackbarType: 'goToFav',
                  });
                } else {
                  this.setAddedToLibOrFavSnackbar({
                    show: true,
                    ctaText: 'snackBarItems.goToLibrary',
                    snackbarText: 'snackBarItems.prezAddedToLib',
                    navFn: this.goToLibrary,
                    snackbarType: 'goToLib',
                  });
                }
                // this.$modal.hide('GenerateActionModal');
              })
              .catch((error) => {
                console.error('Error occurred while creating slide:', error);
                this.generateOperation.loading = false;
                this.generateOperation.success = false;
              });
          } else {
            this.generateOperation.loading = true;
            const payload = {
              source: 'comply',
              complyStatsReferenceId: this.getFileDetails.uploadId,
              isShare: false,
              sharedEntities: [],
              slidesData: tagsData.map((i, idx) => {
                const slideData = this.slidesListResponse?.slide_images[idx];
                return {
                  tags: {
                    uniqueID: i.fnosuffix,
                    node: i.node,
                    construct: i.construct || '',
                    ...i,
                    source: 'comply',
                  },
                  categories: [i.category],
                  construct: i.construct || '',
                  title: name,
                  summary: '',
                  filename: slideData?.pptx?.s3_path,
                  landscape: this.formatProperty(slideData?.pptx?.s3_path),
                  thumbnail: this.formatProperty(slideData?.png?.s3_path),
                  type: 'comply',
                  templateCode: this.selectedTemplate || '',
                  audience: this.currentUser?.user?.id,
                  audienceNumId: this.currentUser?.user?.num_id,
                  audienceType: this.currentUser?.user?.type || 'Me',
                  description: '',
                  keywords: i.keywords,
                  businessIdea: [i.category],
                  acceptedRejectedCount: slideData?.acceptedRejectedCount || 0,
                };
              }),
            };
            await createSlide(payload)
              .then(async (resp) => {
                // this.postCompleteLog('added_to_library', true, resp.data?.id);
                this.addedToLibraryData = {
                  info: resp.data[0],
                  type: 'slide',
                };
                if (this.startSaveChanges) {
                  this.resetState();
                  this.$modal.hide('GenerateActionModal');
                  this.discardChanges();
                }
                this.isAddedToLibrary = true;
                if (origin === 'download') {
                  // this.isAddedToLibraryDownload = true;
                  // this.setIsAddedToLibraryDownload(true);
                  // this.setPremiumFtLibConfPopupData({
                  //   message:
                  //     this.totalSlides > 1
                  //       ? this.$t('premiumFeature.addedToPrezLibrary')
                  //       : this.$t('premiumFeature.addedToSlideLibrary'),
                  //   btnText: this.$t('premiumFeature.openInLibrary'),
                  //   goToLibrary: this.goToLibrary,
                  //   cancel: this.closePopup,
                  // });
                  // setTimeout(() => {
                  //   // this.isAddedToLibraryDownload = false;
                  //   this.resetAddedToLibrarySnackbarState();
                  // }, 10000);
                  this.prezOrSlideId = resp.data[0].id;
                }
                this.generateOperation.loading = false;
                this.generateOperation.success = true;
                if (addFavorite) {
                  await this.addToFavorite();
                  this.setAddedToLibOrFavSnackbar({
                    show: true,
                    ctaText: 'snackBarItems.goToFavorites',
                    snackbarText: 'snackBarItems.slideAddedToFav',
                    navFn: this.goToFavLibrary,
                    snackbarType: 'goToFav',
                  });
                } else {
                  this.setAddedToLibOrFavSnackbar({
                    show: true,
                    ctaText: 'snackBarItems.goToLibrary',
                    snackbarText: 'snackBarItems.slideAddedToLib',
                    navFn: this.goToLibrary,
                    snackbarType: 'goToLib',
                  });
                }
                // this.$modal.hide('GenerateActionModal');
              })
              .catch((error) => {
                console.error('Error occurred while creating slide:', error);
                this.generateOperation.loading = false;
                this.generateOperation.success = false;
              });
          }
        }
      };
      const checkStatus = await setInterval(async () => {
        await getPipeineStatus(callbackId)
          .then(async (res) => {
            if (res.workflow_status === 'success') {
              const tagsData = res?.outputs?.['U-Tags']?.data;
              clearInterval(checkStatus);
              await getTagsData(tagsData);
            }
          })
          .catch((error) => {
            console.log(error);
            clearInterval(checkStatus);
          });
      }, 2000);
    },

    handleAddToSlideLibrary() {
      if (this.isAddedToLibrary) {
        // this.isAddedToLibraryDownload = false;
        // this.resetAddedToLibrarySnackbarState();
        console.log('remove from library');
        if (this.addedToLibraryData.type === 'slide') {
          this.removingFromLibrary = true;
          deleteSlide(this.addedToLibraryData.info.unique_id)
            .then(() => {
              // this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.removingFromLibrary = true;
          removeFromLibrary(this.addedToLibraryData.info.prezentation.id).then(
            () => {
              // this.postCompleteLog('added_to_library', false);
              this.resetSlideLibraryFlags();
              this.removingFromLibrary = false;
              // this.$modal.hide('GenerateActionModal');
            },
          );
        }
      } else {
        const filename = this.modifiedFileName || this.filename;
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.slidesListResponse?.slide_images.length > 1
                ? this.$t('generateToolBar.add_to_prez_exchange')
                : this.$t('generateToolBar.add_to_slide_library'),
            primaryBtnText: this.$t('premiumFeature.addToLibrary'),
            modalType: 'slidesLibrary',
            type:
              this.slidesListResponse?.slide_images.length > 1
                ? 'prezentation'
                : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            operation: this.generateOperation || {},
            filename,
            downloadItem:
              this.selectedSlides.length > 1
                ? {
                    name: filename,
                    categories: ['title'],
                    prefs: {
                      // ...this.selectedSlide.slideDetails.prefs,
                      source: 'comply',
                    },
                    filename,
                    noofslides: this.selectedSlides.length,
                  }
                : {
                    name:
                      this.modifiedFileName ||
                      `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                    prefs: {
                      source: 'comply',
                    },
                    filename: this.selectedSlides[0]?.pptx?.s3_path,
                    noofslides: this.selectedSlides.length,
                  },
            originPage: 'comply',
            getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
            closePreviousPopups: this.handleClosePopAndSaveToLib,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },

    async handleAddToFavorites() {
      if (this.isAddedAsFavorite) {
        if (this.addedToLibraryData.type === 'slide') {
          this.removingAsFavorite = true;
          const params = {
            assetIds: [this.addedToLibraryData.info.unique_id],
            getFavourite: true,
          };
          const resp = await getSlideDetail(params);
          const favSlideId =
            resp.data[this.addedToLibraryData.info.unique_id].favouriteId;
          await deleteFavorite(favSlideId)
            .then(() => {
              // this.postCompleteLog('favorite', false);
              this.isAddedAsFavorite = false;
              this.removingAsFavorite = false;
              // this.$modal.hide('GenerateActionModal');
            })
            .catch((err) => console.log(err));
        } else {
          this.removingAsFavorite = true;
          removePrezentationAsFavorite(
            this.addedToLibraryData.info.prezentation.id,
          ).then(() => {
            // this.postCompleteLog('favorite', false);
            this.isAddedAsFavorite = false;
            this.removingAsFavorite = false;
            // this.$modal.hide('GenerateActionModal');
          });
        }
        console.log('removing favorites');
      } else {
        const filename = this.modifiedFileName || this.filename;
        this.$modal.show(
          GenerateActionModal,
          {
            heading:
              this.slidesListResponse?.slide_images.length > 1
                ? this.$t('premiumFeature.addPrezentationToFavourites')
                : this.$t('premiumFeature.addSlideToFavourites'),
            primaryBtnText: this.$t('slideDetails.addFavorite'),
            modalType: 'favorites',
            type:
              this.slidesListResponse?.slide_images.length > 1
                ? 'prezentation'
                : 'slide',
            primaryBtnAction: this.updateAddedToSlideLibraryStatus,
            isAddedToLibrary: this.isAddedToLibrary,
            operation: this.generateOperation,
            filename,
            downloadItem:
              this.selectedSlides.length > 1
                ? {
                    name: filename,
                    categories: ['title'],
                    prefs: {
                      // ...this.selectedSlide.slideDetails.prefs,
                      source: 'comply',
                    },
                    filename,
                    noofslides: this.selectedSlides.length,
                  }
                : {
                    name:
                      this.modifiedFileName ||
                      `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                    prefs: {
                      source: 'comply',
                    },
                    filename: this.selectedSlides[0]?.pptx?.s3_path,
                    noofslides: this.selectedSlides.length,
                  },
            originPage: 'comply',
            getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
            closePreviousPopups: this.handleClosePopAndSaveToLib,
          },
          {
            name: 'GenerateActionModal',
            width: '500px',
            height: 'auto',
            styles: {
              'max-height': '95%',
              'max-width': '800px',
              overflow: 'auto',
              'border-radius': '20px',
            },
            clickToClose: true,
          },
        );
      }
    },

    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      console.log('selected slides', this.selectedSlides);
      this.slidesListResponse?.slide_images.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },

    async handleClosePopAndSaveToLib(name, addToLib) {
      if (addToLib === true && !this.isAddedToLibrary) {
        await this.updateAddedToSlideLibraryStatus(name);
        // this.isAddedToLibraryDownload = true;
        // this.setIsAddedToLibraryDownload(true);
        // this.setPremiumFtLibConfPopupData({
        //   message:
        //     this.totalSlides > 1
        //       ? this.$t('premiumFeature.addedToPrezLibrary')
        //       : this.$t('premiumFeature.addedToSlideLibrary'),
        //   btnText: this.$t('premiumFeature.openInLibrary'),
        //   goToLibrary: this.goToLibrary,
        //   cancel: this.closePopup,
        // });
        // setTimeout(() => {
        //   // this.isAddedToLibraryDownload = false;
        //   this.resetAddedToLibrarySnackbarState();
        // }, 10000);
      }
      this.$modal.hide('GenerateActionModal');
    },

    checkOverflow() {
      const { mainDiv } = this.$refs;
      if (!mainDiv) return; // Exit if the element doesn't exist

      const adjacentDiv = mainDiv.nextElementSibling;
      if (!adjacentDiv) return;

      const mainDivRect = mainDiv.getBoundingClientRect();
      const adjacentDivRect = adjacentDiv.getBoundingClientRect();

      console.log(
        'main div rect --- ',
        'right --',
        mainDivRect.right,
        'left --',
        mainDivRect.left,
      );

      console.log(
        'adj div rect --- ',
        'right --',
        adjacentDivRect.right,
        'left --',
        adjacentDivRect.left,
      );

      this.isOverflowed = mainDivRect.right > adjacentDivRect.left;
      console.log('Is Overflowed?', this.isOverflowed);
    },

    checkScreenSize() {
      console.log(
        'width',
        window.screen.availWidth,
        'height',
        window.screen.availHeight,
      );
      this.isSmallScreen = window.screen.availWidth <= 1536;
    },
  },
  mounted() {
    if (!this.isComplyFullAccess) {
      this.$router.push('/home', () => {});
      return;
    }
    this.resetState();
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.$store.dispatch('comply/openSmartTools', false);
    });
    // this.checkOverflow();
    // window.addEventListener('resize', this.checkOverflow);

    this.$nextTick(() => {
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);
    });
  },
  beforeDestroy() {
    this.$store.dispatch('comply/abortSignal');
    if (!this.startSaveChanges) {
      this.resetState();
    }
    // this.resetAddedToLibrarySnackbarState();
    window.removeEventListener('resize', this.checkScreenSize);
  },
  beforeRouteLeave(to, from, next) {
    if (
      !this.isAddedToLibrary &&
      this.slidesListResponse &&
      this.slidesListResponse?.slide_images.length &&
      this.isTemplateConvertedCompleted
    ) {
      this.pageChangeAction(next);
    } else {
      next();
    }
  },
};
</script>
<style scss lang="scss">
.comply-content {
  position: relative;
  display: flex;
  height: calc(100vh - 68px);
}

.comply-main-section {
  padding: 20px;
  max-width: 1434px;
  width: 90%;
  margin: 0 auto;
  transition: width 0.5s ease-in;

  &.right-panel-open {
    position: relative;
    width: calc(100% - 620px);
    transition: width 0.5s ease-in;
  }
}

.quick-access-toolbar {
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  background: white;
}

.comply-top-row {
  display: flex;
  justify-content: space-between;
}

// .heading-section {
//   display: flex;
//   flex-direction: column;
//   gap: 3px;
//   justify-content: space-between;
//   &__heading {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 24px;
//     width: 100%;
//     color: #000000;
//   }
//   &__sub {
//     font-family: 'Lato';
//     font-style: normal;
//     font-weight: 400;
//     font-size: 12px;
//     line-height: 14px;
//     letter-spacing: 0.02em;
//     color: #697077;
//     margin-top: 4px;
//   }
// }

.comply-main-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  // height: calc(100% - 61px);
}

.comply-main-wrapper__inner-wrapper {
  margin-right: 1rem;
  flex: 1;
}

.add-ellipses {
  max-width: 565px;
}

// .generate-banner {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   padding: 14px 8px 14px 16px;
//   gap: 4px;
//   height: 48px;
//   background: #e1f5fb;
//   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
//   border-radius: 4px;

//   .dismiss-btn {
//     cursor: pointer;
//   }
// }
</style>
